<template>
	<v-card class="overflow-hidden" elevation="10" align="start" height="365">
		<v-card-text class="full-height-wr">
			<div
				align="center"
				class="subtitle-1 font-weight-bold greyDarken4--text text-uppercase text-truncate"
			>
				{{ $t("app.your_upcoming_meetings") }}
			</div>
			<div align="center" class="text-truncate">
				{{ $t("app.upcoming_meetings_desc") }}
			</div>

			<div v-if="!meetings.length" class="mt-15 pt-10" align="center">
				<v-img
					width="60"
					:src="require('@/assets/images/calendar.png')"
				></v-img>
				<div class="mt-7 subtitle-1 greyDarken4--text">
					{{ $t("app.no_meetings") }}
				</div>
			</div>

			<!-- If meetings are available -->
			<v-list two-line tile v-else class="mt-5">
				<template v-for="(item, index) in meetings">
					<v-list-item :key="index">
						<v-list-item-avatar class="headline font-weight-bold info--text">
							{{ moment(item.start).format("DD") }}
						</v-list-item-avatar>

						<v-list-item-content>
							<!-- Message owner -->
							<v-list-item-title class="greyDarken4--text">
								<v-row no-gutters>
									<v-col>
										{{ item.title }}
									</v-col>
								</v-row>
							</v-list-item-title>

							<v-list-item-subtitle>
								{{ moment(item.start).format("ddd, ll") }}
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action justify="center">
							<v-list-item-action-text>{{
								moment(item.start).format("h:mm A")
							}}</v-list-item-action-text>
							<v-list-item-action-text>
								<v-icon x-small class="ms-5">{{
									$vuetify.icons.values.clock
								}}</v-icon>
								{{ moment(item.end).diff(moment(item.start), "minutes") }}
								{{ $t("app.minutes") }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-list>

			<v-footer
				v-if="meetings.length && $route.name != 'Projects'"
				absolute
				bottom
				color="white"
			>
				<AppButton
					label="app.view_all_meetings"
					color="blueLighten5"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:block="true"
					:class="['mb-1', 'font-weight-bold', 'info--text']"
					@click="redirectTo()"
				></AppButton>
			</v-footer>
		</v-card-text>
	</v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
	name: "DashboardMeetings",

	data() {
		return {
			moment,
		};
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
			getCalendarPlan: "planAndManage/getCalendarPlan",
			getAvailableStepData: "planAndManage/getAvailableStepData",
			isStudent: "user/isStudent",
			isMentor: "user/isMentor",
		}),

		currentStage() {
			return this.getAvailableStepData(
				this.userProfile.available_steps[0].plan_step.id,
			);
		},

		meetings() {
			let plan = this.getCalendarPlan();

			return plan
				.filter((item) => {
					let condition =
						item.step &&
						// start date should be greater than today
						item.start >= moment().format() &&
						// Meeting should only be with mentor
						item.type.toLowerCase() == "withmentor";

					if (this.isMentor) {
						return condition;
					}

					return (
						condition &&
						this.currentStage &&
						// Only take meetings which are ahead of current work.
						item.step.position >= this.currentStage.step.position
					);
				})
				.slice(0, 3);
		},
	},

	mounted() {
		this.getPlan();
	},

	methods: {
		async getPlan() {
			await this.$store.dispatch("planAndManage/getUserPlan");
		},

		redirectTo() {
			this.$router.push({
				name: this.isMentor ? "MentorCalendar" : "ResearchCalendar",
			});
		},
	},
};
</script>

<style></style>
